import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { Card } from 'primereact/card';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import {
  AllReservationsFilter,
  PageResponseReservationListPreviewOutDTO,
  PermissionTypesEnum,
  ReservationDataExportResponseOutDTO,
  ReservationListPreviewOutDTO,
} from '@api/logsteo-api.v2';
import { isNotNullOrUndefined, isNullOrUndefined, useLocalStorage, visualiseWorkflowStateState } from '@utils/utils';
import { Column } from 'primereact/column';
import { dayJsToDate, mapFromAPIDateTime, mapFromAPIToDateLong, mapFromAPIToDateShort, mapFromAPIToTime, mapToAPIDateTime, shortDateFormat } from '@utils/date';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';
import { exportExcel } from '@utils/exports';
import { NoWrapText, RowWithGap } from '@components/styles';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { useCreateTimeslotSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { RecycleButton } from '@components/obsolete/Buttons/RecycleButton/RecycleButton.tsx';
import { Link } from 'react-router-dom';
import DeleteButton from '@components/obsolete/DeleteButton/DeleteButton.tsx';
import { useDuplicateTimeslot } from '@components/obsolete/timeslot/duplicate/duplicate.tsx';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

// Interface for column configuration from API
interface ColumnConfig {
  id: string;
  field: string;
  header: string;
  headerDefault: string;
  width?: string;
  sortable?: boolean;
  showInMobile?: boolean;
  mobileOrder?: number;
}

interface ColumnConfigurationResponse {
  columns: ColumnConfig[];
}

interface ComponentProps {}

export const ResponsiveListReservations: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  const navigation = useNavigation();

  const { getReservations, deleteReservation, exportReservations } = useContext(ApiContext);
  const { loggedUser, hasPermission } = useContext(AuthenticatedUserContext);
  const [storedFilter, setStoredFilter] = useLocalStorage('TIMESLOT_LIST_FILTER', undefined);

  const [data, setData] = useState<PageResponseReservationListPreviewOutDTO>();

  // State for column configuration
  const [columnConfig, setColumnConfig] = useState<ColumnConfig[]>([]);
  // Flag to track if columns have been loaded
  const [columnsLoaded, setColumnsLoaded] = useState<boolean>(false);

  const [revealTabTexts, setRevealTabTexts] = useLocalStorage<boolean>('ringil_tags_expanded', false);

  const { setTimeslotIdForDuplication, DuplicateTimeslot } = useDuplicateTimeslot();
  const { CreateTimeslotSidebar, createTimeslotRequest, setCreateTimeslotRequest } = useCreateTimeslotSidebar();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, applySorting, sort, applyFilter } = useFilter<AllReservationsFilter>(
    (page, filter) => {
      load();
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  useEffect(() => {
    if (isNotNullOrUndefined(storedFilter)) {
      applyFilter(storedFilter);
    }
  }, [storedFilter]);

  // Load column configuration when the component mounts or when the user changes
  useEffect(() => {
    if (loggedUser?.companyId && !columnsLoaded) {
      fetchColumnConfiguration();
    }
  }, [loggedUser?.companyId, columnsLoaded]);

  // Mock implementation of the getColumnOrderSetting API method
  // TODO: Replace this with the actual API call when the backend method is available
  // The real implementation would be something like:
  // apiContext.getColumnOrderSetting(onSuccess, onError)
  const mockGetColumnOrderSetting = (onSuccess: (result: string[]) => void, onError?: (error: Error) => void) => {
    // Simulate API delay
    setTimeout(() => {
      try {
        // Return different column orders based on company code
        if (loggedUser.companyCode === 'Svijany') {
          onSuccess(['reference', 'carrier', 'applicationId', 'locationAndRamp', 'timeslot', 'customer', 'manipulationUnits', 'actions']);
        } else {
          onSuccess(['applicationId', 'locationAndRamp', 'timeslot', 'customer', 'carrier', 'reference', 'manipulationUnits', 'actions']);
        }
      } catch (error) {
        if (onError) {
          onError(error instanceof Error ? error : new Error(String(error)));
        }
      }
    }, 100);
  };

  // Function to fetch column configuration from API
  const fetchColumnConfiguration = () => {
    mockGetColumnOrderSetting(
      (result: string[]) => {
        if (result && Array.isArray(result)) {
          const mappedColumns = mapColumnOrderToColumnConfig(result);
          setColumnConfig(mappedColumns);
        } else {
          setColumnConfig(getDefaultColumnConfig());
        }
        setColumnsLoaded(true);
      },
      (error: Error) => {
        console.error('Failed to load column configuration:', error);
        setColumnConfig(getDefaultColumnConfig());
        setColumnsLoaded(true);
      },
    );
  };

  const mapColumnOrderToColumnConfig = (columnOrder: string[]): ColumnConfig[] => {
    const availableColumns = getAvailableColumns();

    const mappedColumns = columnOrder
      .map(columnId => {
        const column = availableColumns.find(col => col.id === columnId);
        return column || null;
      })
      .filter(column => column !== null) as ColumnConfig[];

    if (mappedColumns.length === 0) {
      return getDefaultColumnConfig();
    }

    return mappedColumns;
  };

  const getAvailableColumns = (): ColumnConfig[] => {
    return [
      { id: 'applicationId', field: 'applicationId', header: 'ID', headerDefault: 'ID', width: '8rem', sortable: true, showInMobile: true, mobileOrder: 1 },
      { id: 'locationAndRamp', field: '', header: 'reservations.expeditionPlace', headerDefault: 'Expedition place', showInMobile: true, mobileOrder: 2 },
      { id: 'timeslot', field: 'timeslotDaySince', header: 'reservations.timeslot', headerDefault: 'Timeslot', sortable: true, showInMobile: true, mobileOrder: 3 },
      { id: 'customer', field: '', header: 'reservations.customer', headerDefault: 'Customer', showInMobile: true, mobileOrder: 4 },
      { id: 'carrier', field: '', header: 'reservations.carrier', headerDefault: 'Carrier', showInMobile: true, mobileOrder: 5 },
      { id: 'reference', field: '', header: 'reservations.reference2', headerDefault: 'Reference', showInMobile: true, mobileOrder: 6 },
      { id: 'manipulationUnits', field: '', header: 'reservations.manipulationUnits', headerDefault: 'Manipulation units', showInMobile: true, mobileOrder: 7 },
      { id: 'actions', field: '', header: 'reservations.actions', headerDefault: 'Actions', showInMobile: false },
    ];
  };

  const getDefaultColumnConfig = (): ColumnConfig[] => {
    return getAvailableColumns();
  };

  const getColumnRenderer = (columnId: string) => {
    const renderFunctions: Record<string, (data: ReservationListPreviewOutDTO) => JSX.Element> = {
      applicationId: renderApplicationId,
      locationAndRamp: renderLocationAndRamp,
      timeslot: renderTimeslot,
      customer: renderCustomer,
      carrier: renderCarrier,
      reference: renderReference,
      manipulationUnits: renderManipulationUnits,
      actions: actionsButtons,
    };

    return renderFunctions[columnId] || (() => <></>);
  };

  const getColumnHeader = (column: ColumnConfig): string => {
    return tr(column.header, column.headerDefault);
  };

  const canEdit = (data: ReservationListPreviewOutDTO) => {
    /*alert(JSON.stringify({ since: data.timeslot?.since, after: dayjs().add(60, 'minutes') }));*/
    // jsem vlastnik
    if (data.customerId == loggedUser.companyId) return true;

    if (data.customerCode !== 'MAGNA-CARTECH') return true;

    // neni zadany datum
    if (isNullOrUndefined(data.timeslot)) return true;

    // neni mene jak 60 minut do zacatku akce
    if (data.customerCode === 'MAGNA-CARTECH' && mapFromAPIDateTime(data.timeslot?.since).isAfter(dayjs().add(60, 'minutes'))) return true;

    return false;
  };

  const load = () => {
    getReservations(
      {
        pageSize: page.pageSize,
        pageNumber: page.pageNumber,
        sortField: sort.sortField,
        sortDirection: sort.sortDirection,
      },
      filter,
      d => {
        setData(d);
      },
    );
  };

  const hasReservation = (data: ReservationListPreviewOutDTO): boolean => {
    if (isNullOrUndefined(data?.timeslot)) return false;
    if (isNullOrUndefined(data?.timeslot.since)) return false;
    if (isNullOrUndefined(data?.timeslot.till)) return false;

    return true;
  };

  const renderTimeslot = (data: ReservationListPreviewOutDTO) => (
    <>
      {hasReservation(data) ? (
        <div>
          <div>{mapFromAPIToDateShort(data.timeslot.since)}</div>
          <div>
            {mapFromAPIToTime(data.timeslot.since)} - {mapFromAPIToTime(data.timeslot.till)}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
  const renderLocationAndRamp = (data: ReservationListPreviewOutDTO) => (
    <>
      <div>{data.locationName}</div>
      <div>{data.rampName}</div>
    </>
  );

  const renderCustomer = (data: ReservationListPreviewOutDTO) => (
    <>
      <div>{data.customerName}</div>
    </>
  );

  const renderCarrier = (data: ReservationListPreviewOutDTO) => (
    <>
      <div>{data.carrierName}</div>
      <div>{[data.driverName, data.carPlate].filter(t => t != null).join(', ')}</div>
    </>
  );
  const renderReference = (data: ReservationListPreviewOutDTO) => (
    <>
      <div>{data.reservationName}</div>
      <div>{data.orders.map(t => t.orderName).join(', ')}</div>
      <div>{mapFromAPIToDateShort(data.preferredDate)}</div>
    </>
  );
  const renderManipulationUnits = (data: ReservationListPreviewOutDTO) => (
    <>
      {data?.orders && (
        <div>
          {data.orders
            .map(t => t.items)
            .flat(1)
            .reduce((acc, val) => {
              if (acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code) === -1) {
                acc.push({
                  cargoTemplate: val.cargoTemplate,
                  amount: val.amount,
                });
              } else {
                const index = acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code);
                acc[index].amount += val.amount;
              }
              return acc;
            }, [])
            .map(t => {
              return `${t.amount} x ${t.cargoTemplate.name}`;
            })
            .join(', ')}
        </div>
      )}
    </>
  );
  const renderApplicationId = (data: ReservationListPreviewOutDTO) => (
    <>
      {/*<Link href={`/timeslots/${data.id}`}>*/}
      <Link to={navigation.createNavigationLink(navigation.urlFunctions.createReservationDetail(data.id))}>
        <TextAndIcon>
          <span className={'text-blue'} style={{ cursor: 'pointer' }}>
            {data.applicationId}
            {data.hasETransportRO ? <>(E)</> : <></>}
          </span>
          {data?.customerId == loggedUser.companyId && <span>{visualiseWorkflowStateState(data?.state, tr, false)}</span>}
        </TextAndIcon>
      </Link>

      {loggedUser && loggedUser.companyId === data.customerId && (
        <LabelWrapper>
          {data.labels
            .sort((a, b) => a.labelValue.localeCompare(b.labelValue))
            .map((t, tIndex) => (
              <LabelDiv
                color={t.labelColor}
                key={tIndex}
                onClick={e => {
                  setRevealTabTexts(v => !v);
                }}
              >
                {revealTabTexts && <div>{t.labelValue}</div>}
              </LabelDiv>
            ))}
        </LabelWrapper>
      )}
    </>
  );

  const deleteRow = (reservationId: string) => {
    deleteReservation(reservationId, () => {
      load();
    });
  };

  const actionsButtons = (data: ReservationListPreviewOutDTO) => (
    <>
      <NoWrapText>
        {data.customerId == loggedUser.companyId && data.timeslot?.since && hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DUPLICATE]) && (
          <RecycleButton
            onClick={() => {
              setTimeslotIdForDuplication(data.id);
            }}
            mode={'round'}
          />
        )}

        {canEdit(data) && hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DELETE]) && (
          <DeleteButton
            onDelete={() => deleteRow(data.id)}
            deleteDialogTitle={tr('reservations.confirmDeteleAction', 'Confirm detele action')}
            deleteDialogMessage={tr('reservations.doYouWantToDeleteTheReservation', 'Do you want to delete the reservation?')}
          />
        )}
      </NoWrapText>
    </>
  );

  const exportExcelHandler = () => {
    exportReservations(filter, d => {
      exportExcel(d.map(mapToExport), 'reservations');
    });
  };

  const mapToExport = (reservation: ReservationDataExportResponseOutDTO) => {
    return {
      applicationId: reservation.applicationId,
      locationName: reservation.locationName,
      since: mapFromAPIToDateLong(reservation?.since),
      till: mapFromAPIToDateLong(reservation?.till),
      carrierName: reservation.carrierName,
      reference: reservation.reference,
      carPlate: reservation.carPlate,
    };
  };

  const saveFilter = () => {
    setStoredFilter(filter);
  };

  const deleteFilter = () => {
    setStoredFilter(undefined);
    applyFilter({
      applicationId: null,
      locationName: null,
      timeslotDaySince: null,
      timeslotDayTill: null,
      carrierName: null,
      referenceOrderId: null,
      reservationName: null,
      driverName: null,
      carPlate: null,
    });
  };

  const { i18n } = useTranslation();

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const [expandedFilters, setExpandedFilters] = useState(false);

  if (isNullOrUndefined(filter)) return <>no filter</>;

  // Function to reset columns and fetch them again
  const refreshColumnConfiguration = () => {
    setColumnsLoaded(false);
    fetchColumnConfiguration();
  };

  // Render columns dynamically based on configuration
  const renderColumns = () => {
    // If columns haven't been loaded yet, show a default set
    if (!columnsLoaded || columnConfig.length === 0) {
      const defaultColumns = getDefaultColumnConfig();
      return defaultColumns.map(column => (
        <Column
          key={column.id}
          body={getColumnRenderer(column.id)}
          header={tr(column.header, column.headerDefault)}
          style={column.width ? { width: column.width } : undefined}
          sortable={column.sortable}
          sortField={column.field || undefined}
        />
      ));
    }

    return columnConfig.map(column => (
      <Column
        key={column.id}
        body={getColumnRenderer(column.id)}
        header={tr(column.header, column.headerDefault)}
        style={column.width ? { width: column.width } : undefined}
        sortable={column.sortable}
        sortField={column.field || undefined}
      />
    ));
  };

  // Render mobile view cards based on column configuration
  const renderMobileCards = () => {
    if (!data?.data) return null;

    return (
      <div className="flex flex-col gap-4">
        {data.data.map((reservation, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow">
            <div className="flex justify-between mb-2">
              <div>
                <Link to={navigation.createNavigationLink(navigation.urlFunctions.createReservationDetail(reservation.id))}>
                  <span className="text-blue-600 font-semibold">
                    {reservation.applicationId}
                    {reservation.hasETransportRO ? ' (E)' : ''}
                  </span>
                </Link>
              </div>
              <div className="flex gap-2">
                {reservation.customerId == loggedUser.companyId && reservation.timeslot?.since && hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DUPLICATE]) && (
                  <RecycleButton onClick={() => setTimeslotIdForDuplication(reservation.id)} mode={'round'} />
                )}
                {canEdit(reservation) && hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DELETE]) && (
                  <DeleteButton
                    onDelete={() => deleteRow(reservation.id)}
                    deleteDialogTitle={tr('reservations.confirmDeteleAction', 'Confirm detele action')}
                    deleteDialogMessage={tr('reservations.doYouWantToDeleteTheReservation', 'Do you want to delete the reservation?')}
                  />
                )}
              </div>
            </div>

            {loggedUser.companyId === reservation.customerId && (
              <LabelWrapper className="mb-2">
                {reservation.labels
                  .sort((a, b) => a.labelValue.localeCompare(b.labelValue))
                  .map((t, tIndex) => (
                    <LabelDiv color={t.labelColor} key={tIndex} onClick={e => setRevealTabTexts(v => !v)}>
                      {revealTabTexts && <div>{t.labelValue}</div>}
                    </LabelDiv>
                  ))}
              </LabelWrapper>
            )}

            <div className="grid grid-cols-2 gap-y-3 gap-x-1 text-sm">
              {columnConfig.map((column, colIndex) =>
                column.id !== 'applicationId' && column.id !== 'actions' ? (
                  <React.Fragment key={colIndex}>
                    <div className="font-semibold">{getColumnHeader(column)}</div>
                    <div>{getColumnRenderer(column.id)(reservation)}</div>
                  </React.Fragment>
                ) : null,
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {/*// @ts-expect-error*/}
      <Card>
        <Row className="flex-col sm:flex-row">
          <Heading className="mb-4 sm:mb-0">{tr(`reservations.reservations`, `Reservations`)}</Heading>
          {hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_LIST_RW]) && (
            <Button
              label={tr(`reservations.newReservation`, `New reservation`)}
              onClick={e => {
                setCreateTimeslotRequest({
                  applicationId: null,
                  companyLocationId: null,
                  preferredDate: '',
                  companyLocationCustomerId: null,
                  expeditionLocationId: null,
                  expeditionId: null,
                });
              }}
            />
          )}
        </Row>

        {/* <div className="my-4">
          <Button
            className="w-full sm:hidden"
            icon={expandedFilters ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
            label={expandedFilters ? tr('reservations.hideFilters', 'Hide filters') : tr('reservations.showFilters', 'Show filters')}
            onClick={() => setExpandedFilters(!expandedFilters)}
          />
        </div> */}

        <FilterRow className={clsx({ 'hidden sm:flex': !expandedFilters })}>
          <div className="w-full sm:w-auto field">
            <InputText
              className="w-full"
              value={filter?.applicationId ? filter?.applicationId : ''}
              placeholder={tr('reservations.applicationId', 'Application ID')}
              onChange={e => applyFilterValue('applicationId', e.target.value)}
            />
          </div>
          <div className="w-full sm:w-auto field">
            <InputText
              className="w-full"
              value={filter?.locationName ? filter?.locationName : ''}
              placeholder={tr('reservations.locality', 'Locality')}
              onChange={e => applyFilterValue('locationName', e.target.value)}
            />
          </div>
          <div className="w-full sm:w-auto field">
            <Calendar
              className="w-full"
              dateFormat={shortDateFormat}
              value={filter.timeslotDaySince ? dayJsToDate(dayjs(filter.timeslotDaySince)) : null}
              placeholder={tr('reservations.since', 'Since')}
              onChange={(e: any) => applyFilterValue('timeslotDaySince', mapToAPIDateTime(dayjs(e.value as Date)))}
              locale={i18n.language}
              showButtonBar={true}
              showIcon
              onClearButtonClick={() => applyFilterValue('timeslotDaySince', null)}
            />
          </div>
          <div className="w-full sm:w-auto field">
            <Calendar
              className="w-full"
              dateFormat={shortDateFormat}
              value={filter.timeslotDayTill ? dayJsToDate(dayjs(filter.timeslotDayTill)) : null}
              placeholder={tr('reservations.till', 'Till')}
              onChange={(e: any) => applyFilterValue('timeslotDayTill', mapToAPIDateTime(dayjs(e.value as Date)))}
              locale={i18n.language}
              showButtonBar={true}
              showIcon
              onClearButtonClick={() => applyFilterValue('timeslotDayTill', null)}
            />
          </div>
          <div className="w-full sm:w-auto field">
            <InputText
              className="w-full"
              value={filter?.carrierName ? filter?.carrierName : ''}
              placeholder={tr('reservations.carrierName', 'Carrier name')}
              onChange={e => applyFilterValue('carrierName', e.target.value)}
            />
          </div>
          <div className="w-full sm:w-auto field">
            <InputText
              className="w-full"
              value={filter?.referenceOrderId ? filter?.referenceOrderId : ''}
              placeholder={tr('reservations.referenceID', 'Reference')}
              onChange={e => applyFilterValue('referenceOrderId', e.target.value)}
            />
          </div>
          <div className="w-full sm:w-auto field">
            <InputText
              className="w-full"
              value={filter?.reservationName ? filter?.reservationName : ''}
              placeholder={tr('reservations.reservationName', 'Reservation name')}
              onChange={e => applyFilterValue('reservationName', e.target.value)}
            />
          </div>
          <div className="w-full sm:w-auto field">
            <InputText
              className="w-full"
              value={filter?.driverName ? filter?.driverName : ''}
              placeholder={tr('reservations.driverName', 'Driver name')}
              onChange={e => applyFilterValue('driverName', e.target.value)}
            />
          </div>
          <div className="w-full sm:w-auto field">
            <InputText
              className="w-full"
              value={filter?.carPlate ? filter?.carPlate : ''}
              placeholder={tr('reservations.carPlate', 'Car plate')}
              onChange={e => applyFilterValue('carPlate', e.target.value)}
            />
          </div>
          <Row className="flex-col sm:flex-row mt-4 sm:mt-0">
            <Button 
              type="button" 
              icon="pi pi-file-excel" 
              onClick={exportExcelHandler} 
              className="p-button-success mb-2 sm:mb-0 sm:mr-2" 
              data-pr-tooltip="XLS" 
            />
            <RowWithGap className="flex-col sm:flex-row">
              <Button 
                label={tr(`Reservations.saveFilter`, `Save filter`)} 
                onClick={e => saveFilter()} 
              />
              {isNotNullOrUndefined(storedFilter) && (
                <Button 
                  label={tr(`Reservations.clearFilter`, `Clear filter`)} 
                  onClick={e => deleteFilter()} 
                />
              )}
            </RowWithGap>
          </Row>
        </FilterRow>

        <TableWrapper>
          {data?.data && (
            <>
              {isMobile && renderMobileCards()}

              {!isMobile && (
                <>
                  {/*// @ts-expect-error*/}
                  <DataTable
                    value={data.data}
                    rows={data.size}
                    paginator={true}
                    lazy={true}
                    totalRecords={data.total}
                    onPage={onPage}
                    first={(data.page - 1) * data.size}
                    sortMode={'single'}
                    onSort={e => {
                      applySorting(e.sortField, e.sortOrder === 1 ? 'ASC' : 'DESC');
                    }}
                    sortField={sort.sortField}
                    sortOrder={sort.sortDirection === 'ASC' ? 1 : -1}
                  >
                    {renderColumns()}
                  </DataTable>
                </>
              )}
            </>
          )}
        </TableWrapper>
      </Card>
      {/*<ConfirmDialog
          visible={reservation2Delete}
          dialogTitle={tr('reservations.confirmDeteleAction', 'Confirm detele action')}
          dialogMessage={tr('reservations.doYouWantToDeleteTheReservation', 'Do you want to delete the reservation?')}
          onConfirm={() => deleteRow(reservation2Delete)}
          onCancel={() => setReservation2Delete(undefined)}
          onAcceptButtonText={tr(`reservations.confirm`, `Confirm`)}
          onCancelButtonText={tr(`reservations.back`, `Back`)}
        />*/}
      <DuplicateTimeslot
        onComplete={() => {
          load();
        }}
      />
      {createTimeslotRequest && (
        <CreateTimeslotSidebar
          onContinue={reservation => {
            load();
          }}
          headerDisabled={false}
        />
      )}
    </>
  );
};

const FilterRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
  margin-top: 0.5rem;

  @media (min-width: 640px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;
  overflow-x: auto;
`;

const Heading = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;

  @media (min-width: 640px) {
    margin: auto 0;
    font-size: 1.75rem;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.3rem 1rem;
  color: white;
  background-color: ${props => props.color || 'black'};
  cursor: pointer;
  margin-top: 0.5rem;
`;

const TextAndIcon = styled.div`
  display: flex;
  gap: 0.5rem;
`;

/*
 * BACKEND API INTEGRATION GUIDE
 *
 * This component uses a mock implementation of the getColumnOrderSetting API method.
 * When implementing the actual backend method, follow these guidelines:
 *
 * 1. Method signature in logsteo-api.v2.tsx:
 *    getColumnOrderSetting: (
 *      onSuccess: (result: string[]) => void,
 *      onError?: (error: Error) => void
 *    ) => void;
 *
 * 2. Expected response format:
 *    An array of column IDs in the order they should be displayed in the table.
 *    Example: ["applicationId", "locationAndRamp", "timeslot", "customer", "carrier", "reference", "manipulationUnits", "actions"]
 *
 * 3. Valid column IDs:
 *    - applicationId: The reservation ID column
 *    - locationAndRamp: Location and ramp information
 *    - timeslot: Time slot information
 *    - customer: Customer name
 *    - carrier: Carrier information
 *    - reference: Reference order information
 *    - manipulationUnits: Manipulation units column
 *    - actions: Action buttons column
 *
 * 4. Company-specific configurations:
 *    Different companies should receive different column orders based on their preferences.
 *    For example, Svijany company has reference and carrier columns first, while others have applicationId first.
 *
 * 5. Integration in the frontend:
 *    Once the backend method is implemented, replace the mockGetColumnOrderSetting call in fetchColumnConfiguration
 *    with the actual API call, for example:
 *
 *    // Update the ApiContext.Provider value to include the new method
 *    const contextValue = {
 *      ...existingMethods,
 *      getColumnOrderSetting
 *    };
 *
 *    // Then in this component, replace:
 *    mockGetColumnOrderSetting(onSuccess, onError)
 *
 *    // With:
 *    import { ApiContext } from '@api/api';
 *    const { getColumnOrderSetting } = useContext(ApiContext);
 *    getColumnOrderSetting(onSuccess, onError)
 */
